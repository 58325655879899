import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FilmFund from "../components/FilmFund/FilmFund"

const FilmFundPage = (props) => (
  <Layout >
    <SEO 
      title="Film Fund" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, language services, news"/>
    <FilmFund location={props.location} />
  </Layout>
)

export default FilmFundPage