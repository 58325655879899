import React, { Component } from 'react'
import { Link } from 'gatsby';

import Header from '../Header/Header'
import Block from "../Block/Block"
import MiniCarousel from '../MiniCarousel/MiniCarousel';

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';


import './FilmFund.css';

import LogoWhite from "../../assets/logo/SleighLogo-White.png"

import Graph1 from '../../assets/graph-one.png';
import Graph2 from '../../assets/graph-two.png';

import HeaderImg from '../../assets/header-bg.jpg';

import ClockIllustration from "../../assets/graphics/clock-illustration.svg";
import InvitationIllustration from "../../assets/graphics/invitation-illustration.svg";
import Portfolio from "../../assets/graphics/portfolio.svg";
import Projects from "../../assets/graphics/projects.svg";
import Handshake from "../../assets/graphics/handshake.svg";
import Greenlight from "../../assets/graphics/greenlight.svg";
import Talent from "../../assets/graphics/talent.svg";
import ThumbsUp from "../../assets/graphics/thumbsup.svg";
import HowWeWork from "../../assets/graphics/how-we-work.png";

import client from "../../client"
import { urlFor } from '../../utils/image-builder'



/**
 *  About page component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class FilmFund extends Component {
    constructor(props) {
        super(props);

        this.state = {
            titles: [],
            isLoading: true
        };


    }

    componentDidMount() {
        this.getTitles()
    }
        

    getTitles = () => {
        // let category = params.get("category")
        let category = this.state.currentCategory

        let queryHead = '_type == "originalContent"'

        // if(category !== null) {
        //     queryHead = `_type == "originalContent" && category._ref == "${category}"`
        // }

        client.fetch(`*[${queryHead}] | order(order asc) 
        {_id, title, slug, mainImage, category, textPreview}`).then(data => { 
                this.setState({titles: data})
            })
            .then(() => this.setState({isLoading: false}))
            .catch(err => console.log(err))
    }
    
    render() {
        var highlights = [
            <div className="carousel-content">
                <div className="icon pink lg-screen">
                    <Talent style={{display: 'block', margin: 'auto', width: '200px'}} />
                </div>
                <div className="icon pink mobile">
                    <Talent style={{display: 'block', margin: 'auto', width: '70px'}} />
                </div>
                
                <h2>Support Fresh Talent</h2>
            </div>,

            <div className="carousel-content">
                <div className="icon pink lg-screen">
                    <Projects style={{display: 'block', margin: 'auto', width: '200px'}} />
                </div>
                <div className="icon pink mobile">
                    <Projects style={{display: 'block', margin: 'auto', width: '70px'}} />
                </div>
                
                <h2>Greenlight Projects</h2>
            </div>,

            <div className="carousel-content">
                <div className="icon pink lg-screen">
                    <Portfolio style={{display: 'block', margin: 'auto', width: '200px'}} />
                </div>
                <div className="icon pink mobile">
                    <Portfolio style={{display: 'block', margin: 'auto', width: '70px'}} />
                </div>
                
                <h2>Diverse Portfolio</h2>
            </div>
        ];

        var process = [
        <div className="carousel-content">
            <div className="icon blue">
                <Handshake style={{display: 'block', margin: 'auto', width: '220px'}} />
            </div>
            
            <p>
                The Sleigh Group Film Fund approaches our international distribution partners 
                and buyers directly, talking to them at all stages of development.
            </p>
        </div>,
        <div className="carousel-content">
            <div className="icon blue">
                <Greenlight style={{display: 'block', margin: 'auto', width: '220px'}} />
            </div>
            
            <p>
                We greenlight the shows with a buyer or distributor interested in them during or 
                after development.
            </p>
        </div>,
        <div className="carousel-content">
            <div className="icon blue">
                <ThumbsUp style={{display: 'block', margin: 'auto', width: '220px'}} />
            </div>
            
            <p>
                Our self funded business model removes funding delays and ensures our shows are 
                delivered on time and without compromising on quality.
            </p>
        </div>
        ];

        return(
            <div id="film-fund">

                {/* <div className="header-film-fund">
                    <div className="overlay">
                        <img src={LogoWhite} />
                        <div className="line"></div>
                        <h1>Film Fund</h1>
                        <div className="header-text animated fadeIn">
                            <p>
                                The Sleigh Group Film Fund curates, develops and produces independent film content directly 
                                to your favourite streaming services, cinema screens and tv networks.
                            </p>
                            <br />
                            <div className="btn blue-btn">LEARN MORE</div>
                        </div>
                    </div>
                    
                </div> */}

                <Header 
                    pageName="Film Fund" 
                    bgColor="linear-gradient(264deg, #282F488C 0%, #282f48 100%)" 
                    bgImage={HeaderImg}
                    title="Film Fund"
                    content="The Sleigh Group Film Fund curates, develops and produces independent film content directly 
                    to your favourite streaming services, cinema screens and tv networks."
                    marginTop="40px"
                    paddingBottom="150px"
                />
                
                
                <div>
                    
                        <section>
                            <div className="highlight">
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <div className="flex-highlight">
                                    {highlights}
                                </div>
                                </ScrollAnimation>
                            
                                <h2>
                                    We provide a pathway for fresh voices and diverse talent to see their visions reach screen,
                                    and provide audiences with compelling, original content from around the world – all in your 
                                    local language.
                                </h2>

                                <div className="line pink-gradient"></div>
                                <p>
                                    For investors, we provide a diverse portfolio of film investments with a lower 
                                    risk profile, a strong return on investment, tax incentives and a commitment that the projects 
                                    we greenlight are those with a buyer or a distributor waiting for them at the other end.
                                </p>

                                <br></br>
                                {/* <div className="carousel">
                                    <MiniCarousel>
                                        {highlights}
                                    </MiniCarousel>
                                </div> */}

                                
                            </div>
                            
                        </section>

                        <section className="opportunity">
                            <ScrollAnimation animateIn="fadeIn" animateOnce>
                                <h1>Bringing Stories To Life</h1>
                                <p>
                                    For film especially, there is a very expensive gap between having an idea and having the 
                                    resources and connections to get it on screen. For many, this means being a content creator 
                                    in their spare time. For most of us, it means increasingly seeing a homogenisation of content 
                                    filling our doomscrolls as we search for ‘something interesting’.                             
                                </p>
                                <br></br>
                                <p>
                                    Increasingly, we’re turning to international media, breaking past the one inch barrier, or finding 
                                    dubbed versions of shows we connect with. With our history in localisation and international media, 
                                    we know how to make content shine across cultural borders, and are always on the look out to provide 
                                    these to our distribution and media platform partners.

                                </p>
                            </ScrollAnimation>

                        </section>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce>
                            <section className="dark why-now">
                                
                                    <ClockIllustration className= "illustration small" />
                                    <ClockIllustration className= "illustration large" />
                                    <div>
                                    <h1>Why now?</h1>
                                    <p>
                                        Australian film is booming, with many international productions taking advantage of our status as 
                                        a COVID safe country, our talent, and our wide ranging geography to tell their stories. We aim to 
                                        continue to foster Australian talent in telling our own stories as well as forming partnerships 
                                        with international creatives to bring new and exciting viewpoints to screens all around the world.
                                    </p>
                                    </div>
                                
                            </section>
                        </ScrollAnimation>

                        <section className="how-we-work">
                            <h1>How we work</h1>
                            <div className="line blue-gradient"></div>
                            {/* <div className="carousel">
                                <MiniCarousel>
                                    {process}
                                </MiniCarousel>
                            </div> */}
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                               <img classname="full-image" src={HowWeWork}/> 
                            </ScrollAnimation>
                            
                            <div className="flex-highlight">
                                    
                                    <div className="steps-text">
                                        <div className="icon blue">
                                            <Handshake style={{display: 'block', margin: 'auto', width: '220px'}} />
                                        </div>
                                        <p>
                                            The Sleigh Group Film Fund approaches our international distribution partners 
                                            and buyers directly, talking to them at all stages of development.
                                        </p>
                                    </div>

                                    <div className="steps-text">
                                        <div className="icon blue">
                                            <Greenlight style={{display: 'block', margin: 'auto', width: '220px'}} />
                                        </div>
                                        <p>
                                            We greenlight the shows with a buyer or distributor interested in them during or 
                                            after development.
                                        </p>
                                    </div>

                                    <div className="steps-text">
                                        <div className="icon blue">
                                            <ThumbsUp style={{display: 'block', margin: 'auto', width: '220px'}} />
                                        </div>
                                        <p>
                                            Our self funded business model removes funding delays and ensures our shows are 
                                            delivered on time and without compromising on quality.
                                        </p>
                                    </div>
                            </div>
                        </section>

                        <section className="numbers">
                            <h1>Numbers</h1>
                            <div className="line pink-gradient"></div>
                            <div className="heading">
                                <h3>
                                    Films in USD $5-20 Million Budget range break even or make money 85% of the time on theatrical releases alone
                                </h3>
                            </div>

                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                            <div className="graph-container">
                                <div>
                                    <p>
                                        <b>Return On Investment For $5-20M Productions Only (2010-2020)</b>
                                    </p>
                                    <img src={Graph1}/>
                                </div>

                                <div>
                                    <p>
                                        <b>Return On Investment by Budget of Movie (2010-2020)</b>
                                    </p>
                                    <img src={Graph2}/>
                                </div>      
                            </div>
                            <div className="heading">
                                <span style={{fontSize: '12px'}}>
                                    <em>
                                     Based on box office data from <a href='https://thenumbers.com/' style={{color: '#282F48', textDecoration: 'underline'}}>https://thenumbers.com/</a>
                                    </em>
                                </span>
                                <p>
                                    Our portfolio approach increases returns and mitigates against individual project risk
                                </p>
                            </div>
                            </ScrollAnimation>
                            <br></br>
                            {/* <div className="btn blue-btn">LEARN MORE</div> */}
                        </section>

                        <section className="invitation dark">
                            <InvitationIllustration className='illustration small' />
                            <ScrollAnimation animateIn="fadeInRight" animateOnce>
                               <InvitationIllustration className='illustration large' /> 
                            </ScrollAnimation>
                            
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                                <div>
                                    <h1>Our Invitation</h1>
                                    <p>
                                        Sleigh Group is opening the fund to sophisticated investors looking to support Australian film 
                                        and diverse voices on screens around the world, but also looking for a sound investment that will 
                                        justify the expenditure. If that’s you, please ask your financial advisor to get in touch with us 
                                        at <a href="mailto:filmfund@sleigh.group" style={{color: 'white', fontWeight: 'bold'}}>filmfund@sleigh.group</a>.
                                    </p>

                                </div>
                            </ScrollAnimation>
                        </section>

                        <section className='titles'>
                            <h1>Titles</h1>
                            <div className='titles-container'>
                                {this.state.titles.map(title => {
                                    return <Link to={`/original-content/${title.slug.current}`}><div className='title'>
                                        <div className="img" style={{backgroundImage: `url(${urlFor(title.mainImage.asset._ref)})`}}/>
                                        {/* <img src={urlFor(title.mainImage.asset._ref)}/> */}
                                        <div className='title-content'>
                                            <div style={{width: '200px', margin: 'auto'}}>
                                                <h2>{title.title}</h2>
                                            </div>
                                            
                                            <div className="line line-alt dark"></div>
                                            <p>{title.textPreview}</p>
                                        </div>
                                    </div></Link>
                                })}
                            </div>
                        </section>
                    
                </div>
            </div>
        )
    }
}