import React, { Component } from 'react';

import { ChevronBackOutline, ChevronForwardOutline } from 'react-ionicons'
import "animate.css/animate.min.css";

import './MiniCarousel.css'

export default class MiniCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0,
            animateRight: false,
            animateLeft: false
        };
    }

    toggleSlide = num => {
        this.setState(prevState => {
            var change = prevState.currentSlide + num;
            var animateRight = true, animateLeft = false;
            if(num < 0) {
                animateLeft = true;
                animateRight = false;
            }

            if(change < 0) {
                change = this.props.children.length - 1
            }

            if(change >= this.props.children.length) {
                change = 0
            } 

            return {
                currentSlide: change,
                animateRight: animateRight,
                animateLeft: animateLeft
            }
        })
    }

    render() {
        var animationClass = '';
        if (this.state.animateRight) {
            animationClass = 'animated fadeInRight'
        } else if (this.state.animateLeft) {
            animationClass = 'animated fadeInLeft'
        }

        return <div>

            <div 
                className={animationClass}  
                onAnimationEnd={() => this.setState({ animateRight: false, animateLeft: false })}>
                {this.props.children[this.state.currentSlide]}
            </div>
            <div className="arrows-container">
                <div className="arrow" onClick={() => this.toggleSlide(-1)}>
                <ChevronBackOutline
                    color={'#282f48'} 
                    height="40px"
                    width="40px"
                    />
                </div>
                <div className="arrow" onClick={() => this.toggleSlide(1)}>
                <ChevronForwardOutline
                    color={'#282f48'} 
                    height="40px"
                    width="40px"
                    />
                </div>
            </div>
        </div>
    }
}
